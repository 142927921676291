body {
  margin: 0;
  font-family: "Oxygen", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p,
span {
  text-align: justify !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-slide-left {
  animation: slideInLeft 0.7s ease-in-out;
}

.animate-slide-right {
  animation: slideInRight 0.7s ease-in-out;
}

@keyframes parallax {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}

.animate-parallax {
  animation: parallax 10s ease-in-out infinite alternate;
  perspective: 1000px;
}

@keyframes floating {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.animate-float {
  animation: floating 3s ease-in-out infinite;
}

@keyframes slideFadeIn {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.animate-slide-fade {
  animation: slideFadeIn 0.8s ease-in-out;
}

@keyframes bounceIn {
  0%,
  100% {
    transform: scale(0.95);
  }
  50% {
    transform: scale(1.05);
  }
}

.animate-bounce {
  animation: bounceIn 0.5s ease-in-out forwards;
}

@keyframes fadeInRotate {
  0% {
    opacity: 0;
    transform: translateY(20px) rotate(-10deg);
  }
  100% {
    opacity: 1;
    transform: translateY(0) rotate(0deg);
  }
}

.animate-fade-rotate {
  animation: fadeInRotate 0.7s ease-in-out;
}

@keyframes zoomIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.animate-zoom-in {
  animation: zoomIn 0.8s ease-out;
}

@keyframes staggerFadeIn {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-stagger {
  display: inline-block;
  animation: staggerFadeIn 0.7s ease-in-out forwards;
}

.stagger-container span {
  display: inline-block;
  opacity: 0;
  animation-delay: calc(var(--index) * 0.05s); /* Adjust delay per letter */
}
